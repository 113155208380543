import React from "react";
import './Footer.css';




function Footer(props) {
    return (
        <div className="footer">
            {/* <NavLink exact="true" className="inactive navlink icon" to="/" >
                <small>Copyright &copy; 2023 Louis Leng. All Rights Reserved</small>
            </NavLink> */}
            <footer>Copyright &copy; 2024 Louis Leng.   All Rights Reserved </footer>

            <div className="footer_right">
                <a href='https://github.com/LouisXO' target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-github"></i>
                </a>
                <a href='https://www.linkedin.com/in/louis-leng/' target="_blank" rel="noopener noreferrer" alt="s">
                    <i className="bi bi-linkedin"></i>
                </a>
                <a href='mailto:louis.leng@outlook.com'>
                    <i className="bi bi-mailbox"></i>
                </a>


            </div>
        </div>

    )

}

export default Footer;