import React from "react";
import { NavLink } from "react-router-dom";
import './NavBar.css';





function NavBar(props) {
    return (
        <div className="top_navbar">
            <NavLink exact="true" className="active navlink name" to="/" >
                Louis Leng
            </NavLink>

            <div className="dropDownMenu">
                
                <button className="dropDownIcon">
                    <div className="icon"></div>
                    <div className="icon"></div>
                    <div className="icon"></div>
                </button>
                <div className="navbar_right">
                    {/* <span class="material-symbols-outlined">
                    menu
                </span> */}

                    <li>
                        <NavLink exact="true" className="inactive navlink" to="/" >
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact="true" className="inactive navlink" to="/project">
                            Projects
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact="true" className="inactive navlink" to="/portfolio">
                            Portfolio
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact="true" className="inactive navlink" to="/gallery">
                            Gallery
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact="true" className="inactive navlink" to="/about">
                            About
                        </NavLink>
                    </li>
                </div>
            </div>

        </div >

    )

}

export default NavBar;