import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from "./component/Main";
import About from "./component/About";
import NavBar from "./component/NavBar";
// import About from "./component/About";
import Footer from "./component/Footer";
import Hawaii from "./component/Hawaii";
import Project from "./component/Project";
import Wordish from "./component/Wordish";
import Gallery from "./component/Gallery";
import Portfolio from "./component/Portfolio";

import './AppRoute.css';


export function AppRoute(props) {
  return (
    <div className="app-route">
      <BrowserRouter>
        <NavBar />
        <div className="app-container" id="app-container">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/project" element={<Project />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/about" element={<About />} />
            <Route path="/gallery" element={<Gallery />} />

            {/* <Route path="/about" element={<About />} /> */}
            <Route path="/hawaii" element={<Hawaii />} />
            <Route path="/wordish" element={<Wordish />} />

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
