import React, { useState, useEffect} from 'react'
import './About.css';



function About() {

	var count = 0;
	// var threeTimes = false;
	const [threeTimes, setThreeTimes] = useState(false);

	useEffect(() => {
		document.title = "About Louis";
	  }, []);

	const tripleClick = (e) => {
		count++;
		if (count === 3) {
			alert('wxl 大漂亮 & 大天才 & 大 Python');
			count = 0;
			setThreeTimes(true);
			// console.log(threeTimes);
			// window.location.reload(false);

		} else {
			setThreeTimes(false);
			return "lol";
		}
	};

	return (
		<div className="contact">
			<h4 className="self-introduce">   Let me introduce myself properly — my Chinese name is 冷乔雨 (Lěng Qiáo Yǔ), but most people know me as Louis. Currently, I'm pursuing my studies in Information Science and Management at Carnegie Mellon University. My academic journey began at the University of Washington 🌸, where I delved into Informatics with a focused emphasis on software engineering.
			<br/>
			<br/>
			Since my high school, I heard a quote that is, <span className='bold'>"Be a voice, not an echo."</span> It resonated deeply with me and has since become my guiding principle in both my personal and professional life. I wish that I could be the best version of myself and make a difference in the world. Be a voice, not an echo. 
			</h4>

			<img src={"./images/BeaVoice.jpeg"} alt="" className='brick'></img>
			<p class="image-caption">My high school class brick </p>

			{/* <h4 className="self-introduce"></h4> */}



			{/* <h3 onClick={tripleClick}>Call me anytime</h3>

			
			<h3><a href='tel:347-291-4550'>+1 347-291-4550</a></h3><div className="intro">
			{threeTimes && <img src={"./images/wxl1.JPG"} alt="wxl1" width={300} height={500}></img>}
			</div> */}
		</div>
	);
}
export default About;
