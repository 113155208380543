import React, { useEffect } from "react";
import './Main.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Main(props) {
    useEffect(() => {
        document.title = "Louis' Space";
    }, []);

    return (
        <div className="main container">
            <div className="intro row align-items-center">
                <div className="col-md-7">
                    <h3>
                        Hey there, I'm Louis Leng, your friendly neighborhood 
                        <span className="bold"> Full-Stack Developer </span> and 
                        <span className="bold"> Photographer </span> 
                        based in <s>Seattle</s> Pittsburgh. 
                        I'm dedicated to crafting innovative solutions and bringing ideas to life through code.
                    </h3>
                </div>
                <div className="col-md-5">
                    <img className="self-pic" src="../images/Self-portrait.jpg" alt="Self-portrait"></img>
                </div>
            </div>

            <div className="work">
                <h2 className="text-center mb-4">Featured Work</h2>
                <div className="row">
                    <div className="col-md-6 mb-4 d-flex align-items-stretch">
                        <div className="card project-card flex-grow-1">
                            <a href="https://melo.louisleng.com" target="_blank" rel="noopener noreferrer">
                                <img className="card-img-top project-photo" src="../images/MeloMap.jpg" alt="MeloMap project"></img>
                            </a>
                            <div className="card-body">
                                <div className="project-title">
                                    <span className="project-year">/2024</span>
                                    <span className="project-name">MeloMap</span>
                                </div>
                                <p className="project-desc">
                                    A social music-sharing platform with location mapping, facilitating music discovery and connections through shared tastes.
                                </p>
                                <ul className="list-inline">
                                    <li className="list-inline-item project-tag">Django</li>
                                    <li className="list-inline-item project-tag">MySQL</li>
                                    <li className="list-inline-item project-tag">Apache</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mb-4 d-flex align-items-stretch">
                        <div className="card project-card flex-grow-1">
                            <a href="https://h-island.louisleng.com" target="_blank" rel="noopener noreferrer">
                                <img className="card-img-top project-photo" src="../images/H-Islands.jpg" alt="H-Islands project"></img>
                            </a>
                            <div className="card-body">
                                <div className="project-title">
                                    <span className="project-year">/2023</span>
                                    <span className="project-name">H-Islands</span>
                                </div>
                                <p className="project-desc">
                                    A quiz vacation matching website that promotes economic recovery in Small Island Developing States (SIDS) by inspiring young travelers to explore these destinations.
                                </p>
                                <ul className="list-inline">
                                    <li className="list-inline-item project-tag">React</li>
                                    <li className="list-inline-item project-tag">SurveyJS</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;
