import './App.css';
import { AppRoute } from "./AppRoute";

function App() {
  return (
    <div className="App">
      <AppRoute />
    </div>
  )

}

export default App;
