import React, { useState, useRef } from 'react'
import './Hawaii.css';
import { LuckyWheel } from '@lucky-canvas/react'


function Hawaii(props) {

	const [blocks] = useState([
		{ padding: '10px', background: '#869cfa' }
	])
	const [prizes] = useState([
		{ background: '#F4DF5F', fontStyle: 'Hind Vadodara', fonts: [{ text: 'Loco Moco', top: 15, fontStyle: 'Hind Vadodara' }] },
		{ background: '#b8c5f2', fonts: [{ text: 'Barefoot Cafe', top: 13, fontStyle: 'Hind Vadodara' }] },
		{ background: '#F4DF5F', fonts: [{ text: 'Sushi Sho', top: 15, fontStyle: 'Hind Vadodara' }] },
		{ background: '#b8c5f2', fonts: [{ text: 'Goma Tei', top: 15, fontStyle: 'Hind Vadodara' }] },
		{ background: '#F4DF5F', fonts: [{ text: 'Island Vintage', top: 12, fontStyle: 'Hind Vadodara' }] },
		{ background: '#b8c5f2', fonts: [{ text: 'Ichiriki', top: 15, fontStyle: 'Hind Vadodara' }] },
	])
	const [buttons] = useState([
		{ radius: '40%', background: '#FFD2E2' },
		{ radius: '35%', background: '#afc8ff' },
		{
			radius: '30%', background: '#869cfa',
			pointer: true,
			fonts: [{ text: 'Start', top: '-10px', fontStyle: 'Hind Vadodara' }]
		}
	])

	const myLucky = useRef()


	return (
		<div className="hawaii">

			{/* <Link to="/love" style={{ textDecoration: 'none', color: '#FFF' }}><h1>Love of My Life</h1></Link> */}
			<div>
				<h1>Restaurant Picker</h1>
				<h3>Welcome to O'ahu</h3>
			</div>
			{/* <SlotMachine width="300px" height="300px" ...你的配置></SlotMachine> */}
			<div className='wheel'>
				<LuckyWheel
					ref={myLucky}
					width="300px"
					height="300px"
					blocks={blocks}
					prizes={prizes}
					buttons={buttons}
					onStart={() => { // 点击抽奖按钮会触发star回调
						myLucky.current.play()
						setTimeout(() => {
							const index = Math.random() * 6 >> 0
							myLucky.current.stop(index)
						}, 2500)
					}}
					onEnd={prize => { // 抽奖结束会触发end回调
						alert('Let\'s go to ' + prize.fonts[0].text)
					}}
				/>
			</div>


		</div >
	);
}

export default Hawaii;