import React , {useEffect}from "react";

// import selfPic from "../images/Self-portrait.jpg"
// import picture from "../images"
import './Gallery.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


import mainPic from "./MainPic.json"

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';




function Gallery(props) {
	SwiperCore.use([Autoplay]);
	useEffect(() => {
		document.title = "Louis' Gallery";
	  }, []);

// CREATE something like this https://momento.framer.website/
	return (
		<div className="main">
			<h2>Earth - The greatest artist</h2>

			<Swiper
				loop={true}
				autoplay={{
					"delay": 4000,
					"disableOnInteraction": true


				}}

				speed={1000}
				spaceBetween={100}
				style={{
					'--swiper-navigation-color': '#fff',
					'--swiper-pagination-color': '#fff',
				}}
				lazy={true}
				pagination={{
					clickable: true,
				}}
				// navigation={true}
				modules={[Pagination, Navigation]}
				className="mySwiper"


			>
				{mainPic.map((picture) => (

					<SwiperSlide>
						<img
							src={picture.imageUrl}
							loading="lazy"
							alt={picture.title}
							className="swiper-pic"
							key={picture.id}
						/>
						<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
					</SwiperSlide>

				))}
				{/* <SwiperSlide>
					<img
						src={selfPic}
						loading="lazy"
						alt=""
						sizes=""
						className="swiper-pic"
					/>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</SwiperSlide>
				<SwiperSlide>
					<img
						src={picture}
						loading="lazy"
						className="swiper-pic"
					/>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</SwiperSlide>
				<SwiperSlide>
					<img
						src={selfPic}
						loading="lazy"
						className="swiper-pic"
					/>
					<div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
				</SwiperSlide> */}

			</Swiper>


			{/* <div className="intro">
				<h3>I’m a full stack developer & photographer based in <strike color='white'>Seattle</strike> Pittsburgh.</h3>
				<img className="self-pic" src={selfPic} width={1000} alt="self-pic"></img>
			</div> */}
			{/* <div className="intro">
				<h3>I’m a full stack developer & photographer based in <strike color='white'>Seattle</strike> Pittsburgh.</h3>
				<img className="self-pic" src={selfPic} width={1000} ></img>
			</div>
			<div className="intro">
				<h3>I’m a full stack developer & photographer based in <strike color='white'>Seattle</strike> Pittsburgh.</h3>
				<img className="self-pic" src={selfPic} width={1000} ></img>
			</div>
			<div className="intro">
				<h3>I’m a full stack developer & photographer based in <strike color='white'>Seattle</strike> Pittsburgh.</h3>
				<img className="self-pic" src={selfPic} width={1000} ></img>
			</div>
			<div className="intro">
				<h3>I’m a full stack developer & photographer based in <strike color='white'>Seattle</strike> Pittsburgh.</h3>
				<img className="self-pic" src={selfPic} width={1000} ></img>
				</div> */}

		</div>
	);
}

export default Gallery;