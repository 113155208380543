import React, { useEffect } from 'react';
import './Portfolio.css';

function Portfolio(props) {

	useEffect(() => {
		document.title = "Louis' Portfolio";
	  }, []);


	return (
		<div className="portfolio">
			<h3> This is my travel album</h3>
			<h4><a href='https://www.instagram.com/p/CqOU-6tPHSC/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Hawaii</a></h4>
			<h4><a href='https://www.instagram.com/p/Crcf8OPOcwB/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Seattle</a></h4>
			<h4><a href='https://www.instagram.com/p/Ctt0bY9rAYq/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">New York</a></h4>
			<h4><a href='https://www.instagram.com/p/Cr7YcI3OsFF/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Vancouver</a></h4>
			<h4><a href='https://www.instagram.com/p/CxWvHSmrWJe/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Hong Kong</a></h4>
			<h4><a href='https://www.instagram.com/p/C0ph9IEL6lw/?utm_source=ig_web_button_share_sheet=' target="_blank" rel="noreferrer">Las Vegas</a></h4>
			<h4><a href='https://www.instagram.com/p/C1u9s0VuNOD/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Antelope Canyon</a></h4>
{/* 
			<h4><a href='https://www.instagram.com/p/CqOU-6tPHSC/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Hawaii</a></h4>
			<h4><a href='https://www.instagram.com/p/Crcf8OPOcwB/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Seattle</a></h4//www.instagram.com/p/C0ph9IEL6lw/?igsh=MWJlMWozdzRiOWUxMQ==h4>
			<h4><a href='https://www.instagram.com/p/Ctt0bY9rAYq/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">New York</a></h4>
			<h4><a href='https://www.instagram.com/p/Cr7YcI3OsFF/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Vancouver</a></h4>
			<h4><a href='https://www.instagram.com/p/CxWvHSmrWJe/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Hong Kong</a></h4><h4><a href='https://www.instagram.com/p/CqOU-6tPHSC/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Hawaii</a></h4>
			<h4><a href='https://www.instagram.com/p/Crcf8OPOcwB/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Seattle</a></h4>
			<h4><a href='https://www.instagram.com/p/Ctt0bY9rAYq/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">New York</a></h4>
			<h4><a href='https://www.instagram.com/p/Cr7YcI3OsFF/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Vancouver</a></h4>
			<h4><a href='https://www.instagram.com/p/CxWvHSmrWJe/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Hong Kong</a></h4><h4><a href='https://www.instagram.com/p/CqOU-6tPHSC/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Hawaii</a></h4>
			<h4><a href='https://www.instagram.com/p/Crcf8OPOcwB/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Seattle</a></h4>
			<h4><a href='https://www.instagram.com/p/Ctt0bY9rAYq/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">New York</a></h4>
			<h4><a href='https://www.instagram.com/p/Cr7YcI3OsFF/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Vancouver</a></h4>
			<h4><a href='https://www.instagram.com/p/CxWvHSmrWJe/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==' target="_blank" rel="noreferrer">Hong Kong</a></h4> */}
			


		</div >
	);
}

export default Portfolio;