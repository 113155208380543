import React, { useEffect } from 'react';

import './Project.css';



function Project(props) {
	// const routeWordish = () => {
	// 	return <Navigate to="/wordish" replace />
	// }

	useEffect(() => {
		document.title = "Louis' Project";
	  }, []);


	return (
		<div className="project">
			<h3>Here are my projects</h3>
			<h4><a href='https://ami.louisleng.com' target="_blank" rel="noreferrer">Ami</a></h4>
			<h4><a href='https://h-island.louisleng.com' target="_blank" rel="noreferrer">H-Island</a></h4>
			<h4><a href='https://ischool.uw.edu/news/2023/05/informatics-students-show-their-work-research-symposium/' target="_blank" rel="noreferrer">YouCred</a></h4>
			<h4><a href='/wordish'> Wordish </a></h4>
			<h4><a href='https://melo.louisleng.com/' target="_blank" rel="noreferrer"> MeloMap </a></h4>

		</div>
	);
}

export default Project;