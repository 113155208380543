import React, { useState} from 'react'
import { ToastContainer, toast } from 'react-toastify';
import all_words_json from './all_words.json';
import 'react-toastify/dist/ReactToastify.css';
import './Wordish.css';




function Wordish(props) {

	const [matrix, setMatrix] = useState([]);
	const [gameStart, setGameStart] = useState(false);
	const [gameEnd, setGameEnd] = useState(false);
	const [target, setTarget] = useState('');
	const [guess, setGuess] = useState('');
	const [oldGuess, setOldGuess] = useState('');
	const all_words = [];

	Object.entries(all_words_json.all_words).map((key) =>
		all_words.push(key[1]))


	function onclickStart() {
		if (checkValidInput(target)) {
			setGameStart(true);
			const emptyMatrix = [];
			for (let i = 0; i < 6; i++) {
				emptyMatrix.push([])
				for (let j = 0; j < 5; j++) {
					var cell = { "color": "grey", "id": "cell_" + i + "_" + j, "letter": "" }
					emptyMatrix[i].push(cell)
				}
			}
			setMatrix(emptyMatrix);
		}
	}

	function onclickRandomStart() {
		setTarget(all_words[Math.floor(Math.random() * all_words.length)]);
		setGameStart(true);
		const emptyMatrix = [];
		for (let i = 0; i < 6; i++) {
			emptyMatrix.push([])
			for (let j = 0; j < 5; j++) {
				var cell = { "color": "grey", "id": "cell_" + i + "_" + j, "letter": "" }
				emptyMatrix[i].push(cell)
			}
		}
		setMatrix(emptyMatrix);
	}

	function onclickGuess() {
		if (checkValidInput(guess)) {
			setOldGuess(oldGuess + guess);
			const matrixTemp = [...matrix]
			var row, column = 0;

			const color = compute_color();

			for (var i = 0; i < guess.length; i++) {
				row = Math.floor((i + oldGuess.length) / 5);
				column = i % 5;
				matrixTemp[row][column].letter = guess[i].toUpperCase();
				matrix[row][column].color = color[i];
			}
			setMatrix(matrixTemp);
			checkGameStatus();
		}


	}

	function checkGameStatus() {
		if (gameStart) {
			console.log(oldGuess.length);
			if (guess === target) {
				toast.success('Congratulations! You have guessed the word!', {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				toast.info('Wanna Try Again?', {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				setGameEnd(true);
			} else if (oldGuess.length >= 25) {
				toast.error('Sorry! You have run out of guesses!', {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				toast.info('Wanna Try Again?', {
					position: "top-right",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				setGameEnd(true);
			}
		}
	}

	function restartGame() {
		setGameStart(false);
		setGameEnd(false);
		setOldGuess('');
		setTarget('');
		setGuess('');
	}

	function StatusBar(){
		let text = "Welcome to Wordish!"
		console.log(target)
		if (gameEnd) {
			text = "Game Over! The target was " + target.toUpperCase();

		}
		return (<div id="status">{text}</div>)
	}



	function compute_color() {
		const color = [];
		var targetLeft = target;
		for (var i = 0; i < 5; i++) {
			if (guess[i] === target[i]) {
				color.push('green');
				targetLeft = targetLeft.substring(0, i) + ' ' + targetLeft.substring(i + 1);
			} else {
				color.push('lightgrey');
			}
		}

		for (var j = 0; j < 5; j++) {
			if (targetLeft.includes(guess[j]) && color[j] === 'lightgrey') {
				// targetLeft = targetLeft[: targetLeft.index(guess[i])]+ ' ' + targetLeft[targetLeft.index(guess[i]) + 1:]
				targetLeft = targetLeft.substring(0, targetLeft.indexOf(guess[j])) + ' ' + targetLeft.substring(targetLeft.indexOf(guess[j]) + 1);
				color[j] = 'yellow';
			}
		}
		return color;
	}

	function checkValidInput(input) {
		var valid = true;
		for (var i = 0; i < 5; i++) {
			if (input.length !== 5 || !/^[a-zA-Z]+$/.test(input[i])) {
				valid = false;
			}
		}
		if (!all_words.includes(input)) {
			valid = false;
		}
		if (!valid) {
			toast.error('Please enter a five letter word!', {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}

		return valid
	}



	return (<div>
		<div className="game">
			<StatusBar />

			<ToastContainer />


			{gameStart ?
				<div>
					<div className="matrix">
						{matrix.map((row) => {
							return row.map((cell) => (<div className={"cell " + cell.color} id={cell.id} key={cell.id} >
								{cell.letter}
							</div>))
						})}
					</div>

					{!gameEnd ?
						<div className="guess">
							<label>Guess:</label>
							<input placeholder="guess the word" id="guess_text" autoFocus onChange={(e) => setGuess(e.target.value)} />
							<button className="button" id="guess_button" type="submit" onClick={onclickGuess}>
								Submit
							</button>
						</div>
						:
						<div className="guess">
							<button className="button" id="guess_button" type="submit" onClick={restartGame}>
								Let's Go Again!
							</button>
						</div>}
				</div>

				: null}

			{!gameStart ?
				<div>
					<div className="start">
						<label>New Target: &nbsp;</label>
						<input placeholder="Let's set a target" id="target_text" autoFocus onChange={(e) => setTarget(e.target.value)} />
						<button className="start_button button" id="start_button" onClick={onclickStart}>
							Start
						</button>
					</div>
					<div>
						<label>Random Target: &nbsp;</label>
						<button className="start_button button" id="start_button" onClick={onclickRandomStart}>
							Start
						</button>
					</div>
				</div>
				: null}
		</div>
	</div >

	)
}


export default Wordish;
